<template lang="pug">
  #login
    .container-fluid.py-0
      .row.full-layout
        .login-notification(
          v-bind:class="{active: loginNotification.active}"
          v-bind:style="{ backgroundColor: loginNotification.color }"
        )
          p.mb-0 {{ loginNotification.text }}
        .col-5.auto
          b-card.pa-5
            .row.mx-0
              .col-12
                .row.title.d-flex.mx-0.mt-3.mb-1
                  h4.light-font PLATAFORMA
                  h4.bold-font CIUDADANA DE FOSAS
              .col-12.pt-4
                b-form.px-5
                  b-form-group(
                    id="email-input-g"
                    label="E-mail:"
                    label-for="email-input"
                  )
                    b-form-input(
                      id="email-input"
                      v-model="email"
                      type="email"
                      placeholder="Introduzca su correo"
                      :state="validInput.email"
                      required
                    )
                    b-form-invalid-feedback(:state="validInput.email")
                      | No es una dirección de correo válida
                  b-form-group(
                    id="pass-input-g"
                    label="Contraseña:"
                    label-for="text-password"
                  )
                    b-input-group
                      b-form-input(
                        :type="e1 ? 'password' : 'text'"
                        id="text-password"
                        v-model="password"
                        placeholder="Introduzca su contraseña"
                        :state="validInput.password"
                        required
                      )
                      b-input-group-append
                        b-input-group-text
                          b-icon(:icon="!e1 ? 'eye' : 'eye-slash'" @click="e1 = !e1")
                      b-form-invalid-feedback(:state="validInput.password")
                        | La contraseña es requerida
                    button.main-color-button.bold.mt-5.mb-4(@click.prevent="login") Entrar

</template>

<script>
export default {
  name: "Login",
  props: {},
  data() {
    return {
      email: "",
      e1: true,
      password: "",
      validInput: {
        email: null,
        password: null,
      },
      loginNotification: {
        active: false,
        text: "",
      },
    };
  },
  computed: {
  },
  methods: {
    inputValidation() {
      this.validInput.email = true;
      this.validInput.password = true;
      let emailValidation = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
        this.email
      );

      if (
        this.email === "" ||
        typeof this.email === "undefined" ||
        !emailValidation
      ) {
        this.validInput.email = false;
      }

      if (this.password === "" || typeof this.password === "undefined") {
        this.validInput.password = false;
      }
    },
    async login() {
      this.inputValidation();
      if (!Object.values(this.validInput).includes(false)) {
        //this.$store.commit("setIsLoadingState", true);
        try {
          let loginResponse = await this.$store.dispatch("login", {
            email: this.email,
            password: this.password,
          });

          this.loginNotification = {
            active: true,
            text: "Usuario autenticado",
            color: "green",
          }

          setTimeout(() => {
            this.loginNotification = {
              active: false,
              text: "",
              color: "",
            };
          }, 3000);

          this.$store.state.user = loginResponse.data;

          this.email = "";
          this.password = "";
          this.$router.replace({ name: "documents" });
        } catch (error) {
          this.loginNotification = {
            active: true,
            text: "Hubo un error",
            color: "firebrick",
          };
          setTimeout(() => {
            this.loginNotification = {
              active: false,
              text: "",
              color: "",
            };
          }, 3000);
        }
      }
    },
  },
};
</script>

<style lang="scss">
#login,
.container,
.full-layout {
  text-align: center;
  justify-content: center;
  height: 100vh;
  .auto {
    margin: auto;
  }
}
#login {
  width: 100% !important;
  background-color: #CCCBCD;
  .card {
    background-color: #191a1a;
    .title {
      flex-direction: column;
      justify-content: center;
      color: white;
      h4.bold-font {
        margin: auto;
        color: #191a1a;
        background-color: #e0e0e0;
        width: fit-content;
        padding: 0px 5px;
      }
    }
  }
  input {
    font-size: 14px;
    line-height: 28px;
  }
  .valid-feedback,
  .invalid-feedback {
    font-size: 12px;
  }
  img {
    width: 100%;
    height: auto;
  }
  .input-group-text {
    height: 100%;
  }
  button {
    width: 50%;
    height: 45px;
    border-radius: 8px;
    border: 1px solid white;
    background-color: #191a1a;
    color: white;
  }
  .login-notification {
    width: 200px;
    height: auto;
    display: none;
    position: fixed;
    top: 44%;
    z-index: 1;
    right: 1%;
    border-radius: 5px;
    padding: 10px;
  }
  .active {
    display: block !important;
  }
}
.d-block {
  display: flex !important;
  font-size: 14px;
}
input[type=text], textarea {
  background-color: white;
  color: #191a1a;
}
input[type=search], input[type=search]:focus {
  background-color: white;
  min-height: 35px;
}
input[type=radio], input[type=checkbox] {
  margin-right: 10px;
}

</style>
